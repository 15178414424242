import React from 'react'
import './Footer.css'
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaPinterestSquare,
  FaHeart
} from 'react-icons/fa'

export default () => (
  <div className="footer">
    <br />
    <div>
      <h2 className="taCenter">
        Follow us <br />
        <a
          href="https://www.facebook.com/SAR-Industries-108640820920292"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookSquare size="2em" />
        </a>{' '}
        <a
          href="https://www.instagram.com/sarindustries.india/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagramSquare size="2em" />
        </a>{' '}
        {/* <a href="" target="_blank">
          <FaTwitterSquare size="1.5em" />
        </a>{' '} */}
        <a
          href="https://www.linkedin.com/company/sar-industries/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin size="2em" />
        </a>{' '}
        <a href="https://in.pinterest.com/SARIndustries1996" target="_blank" rel="noreferrer">
          <FaPinterestSquare size="2em" />
        </a>{' '}
      </h2>
    </div>
    <footer className="bottomFooter">
      <div className="container taCenter">
        <span>
          Made with <FaHeart size="1em" /> in India
          <br/>
          <span style={{fontSize:"0.6em"}} >
          <b>Disclaimer:</b> The Product Part number and images shared on the website are for reference purpose only. If you have issue or suggestion for us, then please mail the same to sarindustries7@gmail.com.
          </span>
          {/* Images used are just for reference purposes only., we do not imply part numbers for any OEM product. <br /> */}
        </span>
      </div>
    </footer>
  </div>
)
